import { useState } from 'react'

import { object as YupObject, string as YupString } from 'yup'

import { showRiverLocation } from '../../../Domain/River'
import { useSettingsContext } from '../../../providers/SettingsProvider'

import type { HubLike, HubLikeId, LaneId } from '../../../generated/graphql'

export enum NominationStage {
  LaneSelection,
  VesselSelection,
  DepartureTimeSelection,
  TowParametersSelection,
}

type CurrentStage = {
  stage: NominationStage
}

type LaneSelectionStage = Omit<CurrentStage, 'stage'> & {
  stage: NominationStage.LaneSelection
}

type VesselSelectionStage = Omit<CurrentStage, 'stage'> & {
  stage: NominationStage.VesselSelection
}

type DepartureTimeSelectionStage = Omit<CurrentStage, 'stage'> & {
  stage: NominationStage.DepartureTimeSelection
}

type TowParametersSelectionStage = Omit<CurrentStage, 'stage'> & {
  stage: NominationStage.TowParametersSelection
}

const isLaneSelectionStage = (cs: CurrentStage): cs is LaneSelectionStage => cs.stage === NominationStage.LaneSelection
const isVesselSelectionStage = (cs: CurrentStage): cs is VesselSelectionStage =>
  cs.stage === NominationStage.VesselSelection
const isDepartureTimeSelectionStage = (cs: CurrentStage): cs is DepartureTimeSelectionStage =>
  cs.stage === NominationStage.DepartureTimeSelection
const isTowParametersSelectionStage = (cs: CurrentStage): cs is TowParametersSelectionStage =>
  cs.stage === NominationStage.TowParametersSelection

type StageTransition = {
  next: NominationStage | null
  previous: NominationStage | null
}

const stageTransitions: Record<NominationStage, StageTransition> = {
  [NominationStage.LaneSelection]: { previous: null, next: NominationStage.VesselSelection },
  [NominationStage.VesselSelection]: {
    previous: NominationStage.LaneSelection,
    next: NominationStage.DepartureTimeSelection,
  },
  [NominationStage.DepartureTimeSelection]: {
    previous: NominationStage.VesselSelection,
    next: NominationStage.TowParametersSelection,
  },
  [NominationStage.TowParametersSelection]: { previous: NominationStage.DepartureTimeSelection, next: null },
}

export type StageActions = {
  nextStage: (() => void) | null
  previousStage: (() => void) | null
}

export type StageData<F, S> = {
  isSelected: boolean
  form: F
  summary: S
  actions: StageActions
}

export type LaneSelectionFormValues = {
  laneId: LaneId | undefined
  origin: HubLikeId | undefined
  destination: HubLikeId | undefined
}

export type LaneSelectionSummaryValues = {
  description: string
}

export type StageFormData<F> = {
  values: F
  onChange: (values: F) => void
}

export type StageSummaryData<S> = S

export type NominationFormViewModel = {
  currentStage: CurrentStage
  stages: Record<NominationStage, StageData<StageFormData<any>, StageSummaryData<any>>>
}

const initialState = {
  stage: NominationStage.LaneSelection,
}

export const laneSelectionInitialValues: LaneSelectionFormValues = {
  laneId: undefined,
  origin: undefined,
  destination: undefined,
}

export const laneSelectionValidationSchema = YupObject().shape(
  {
    laneId: YupString().required('Required'),
    origin: YupString().required('Required'),
    destination: YupString().required('Required'),
  },
  []
)

const buildLaneDescription = (
  laneSelection: LaneSelectionFormValues,
  lanes: Record<LaneId, string>,
  hubs: Record<HubLikeId, HubLike>
): string => {
  const { laneId, origin, destination } = laneSelection
  if (!laneId || !origin || !destination) {
    return 'not set'
  }
  const lane = lanes[laneId]
  const originLocation = showRiverLocation(hubs[origin].riverLocation)
  const destinationLocation = showRiverLocation(hubs[destination].riverLocation)

  return `${lane}: ${originLocation} to ${destinationLocation}`
}

const useNominationFormViewModel = (): NominationFormViewModel => {
  const [stage, setStage] = useState(initialState.stage)
  const [laneSelectionForm, changeLaneSelectionForm] = useState(laneSelectionInitialValues)
  const [vesselSelectionForm, changeVesselSelectionForm] = useState({})
  const [departureTimeSelectionForm, changeDepartureTimeSelectionForm] = useState({})
  const [towParametersSelectionForm, changeTowParametersSelectionForm] = useState({})

  const { lanes, hubs } = useSettingsContext()

  const maybeChangeStageHandler = (target: NominationStage | null) => {
    // enums start with 0, 0 is falsy, explicit comparison needed to avoid implicit type conversion
    return target !== null ? () => setStage(target) : null
  }

  const stageActions = (currentStage: NominationStage): StageActions => {
    return {
      nextStage: maybeChangeStageHandler(stageTransitions[currentStage].next),
      previousStage: maybeChangeStageHandler(stageTransitions[currentStage].previous),
    }
  }

  const currentStage = {
    stage,
  }

  const stages = {
    [NominationStage.LaneSelection]: {
      isSelected: isLaneSelectionStage(currentStage),
      form: {
        values: laneSelectionForm,
        onChange: changeLaneSelectionForm,
      },
      summary: {
        description: buildLaneDescription(laneSelectionForm, lanes, hubs),
      },
      actions: stageActions(NominationStage.LaneSelection),
    },
    [NominationStage.VesselSelection]: {
      isSelected: isVesselSelectionStage(currentStage),
      form: {
        values: vesselSelectionForm,
        onChange: changeVesselSelectionForm,
      },
      summary: {},
      actions: stageActions(NominationStage.VesselSelection),
    },
    [NominationStage.DepartureTimeSelection]: {
      isSelected: isDepartureTimeSelectionStage(currentStage),
      form: {
        values: departureTimeSelectionForm,
        onChange: changeDepartureTimeSelectionForm,
      },
      summary: {},
      actions: stageActions(NominationStage.DepartureTimeSelection),
    },
    [NominationStage.TowParametersSelection]: {
      isSelected: isTowParametersSelectionStage(currentStage),
      form: {
        values: towParametersSelectionForm,
        onChange: changeTowParametersSelectionForm,
      },
      summary: {},
      actions: stageActions(NominationStage.TowParametersSelection),
    },
  }

  return {
    currentStage,
    stages,
  }
}

export default useNominationFormViewModel
