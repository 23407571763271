import React, { type FC } from 'react'

import FormBlockWithActions from './FormBlockWithActions'

import type { StageActions, StageData } from './viewModel'

const TowParametersSelectionForm: FC<StageActions> = ({ nextStage, previousStage }) => {
  return (
    <FormBlockWithActions nextStage={nextStage} previousStage={previousStage}>
      Departure selection form
    </FormBlockWithActions>
  )
}

const TowParametersSelectionSummary: FC<{}> = () => {
  return <div>Tow parameters summary</div>
}

const TowParametersSelectionBlock: FC<StageData<any, any>> = ({ isSelected, actions }) => {
  const { nextStage, previousStage } = actions
  return isSelected ? (
    <TowParametersSelectionForm nextStage={nextStage} previousStage={previousStage} />
  ) : (
    <TowParametersSelectionSummary />
  )
}

export default TowParametersSelectionBlock
