import React, { type FC, type PropsWithChildren } from 'react'

import styles from './styles.module.scss'

import type { StageActions } from './viewModel'

const FormBlockWithActions: FC<PropsWithChildren<StageActions>> = ({ children, previousStage, nextStage }) => {
  return (
    <div className={styles.formWithActions}>
      <div className={styles.formBlock}>{children}</div>
      <div className={styles.actionBlock}>
        {previousStage ? <button onClick={previousStage}>previousStage</button> : null}
        {nextStage ? <button onClick={nextStage}>nextStage</button> : null}
      </div>
    </div>
  )
}

export default FormBlockWithActions
