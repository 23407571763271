import React, { type FC } from 'react'

import { Form, Formik } from 'formik'

import { HubLikeSelector } from '../../../components/Header/HubLikeSelector/HubLikeSelector'
import { LaneSelectorCombobox } from '../../../components/Header/LaneSelector/LaneSelector'

import FormBlockWithActions from './FormBlockWithActions'
import styles from './styles.module.scss'
import {
  type LaneSelectionFormValues,
  laneSelectionInitialValues,
  type LaneSelectionSummaryValues,
  laneSelectionValidationSchema,
  type StageActions,
  type StageData,
} from './viewModel'

import type { HubLikeId, LaneId } from '../../../generated/graphql'

const LaneSelectionForm: FC<
  StageActions & { data: LaneSelectionFormValues; onChange: (d: LaneSelectionFormValues) => void }
> = ({ nextStage, previousStage, data, onChange }) => {
  return (
    <Formik
      initialValues={laneSelectionInitialValues}
      validationSchema={laneSelectionValidationSchema}
      onSubmit={() => {}}>
      {({ setFieldValue }) => (
        <Form className={styles.nominationForm}>
          <FormBlockWithActions nextStage={nextStage} previousStage={previousStage}>
            <h3>Location</h3>
            <div>
              <span>Lane</span>
              <span className={styles.select}>
                <LaneSelectorCombobox
                  lane={data.laneId}
                  handleChange={item =>
                    setFieldValue('laneId', item as LaneId).then(() => onChange({ ...data, laneId: item as LaneId }))
                  }
                />
              </span>
            </div>
            <div>
              <span>Origin</span>
              <span className={styles.select}>
                <HubLikeSelector
                  isDisabled={false}
                  value={data.origin}
                  handleChange={item =>
                    setFieldValue('origin', item as HubLikeId).then(() =>
                      onChange({ ...data, origin: item as HubLikeId })
                    )
                  }
                  placeholder="Select origin"
                  filter={() => true}
                  readOnly={false}
                />
              </span>
            </div>
            <div>
              <span>Destination</span>
              <span className={styles.select}>
                <HubLikeSelector
                  isDisabled={false}
                  value={data.destination}
                  handleChange={item =>
                    setFieldValue('destination', item as HubLikeId).then(() =>
                      onChange({ ...data, destination: item as HubLikeId })
                    )
                  }
                  placeholder="Select destination"
                  filter={() => true}
                  readOnly={false}
                />
              </span>
            </div>
          </FormBlockWithActions>
        </Form>
      )}
    </Formik>
  )
}

const LaneSelectionSummary: FC<LaneSelectionSummaryValues> = ({ description }) => {
  return (
    <div>
      <h3>Location</h3>
      <div>{description}</div>
    </div>
  )
}

const LaneSelectionBlock: FC<
  StageData<
    { values: LaneSelectionFormValues; onChange: (d: LaneSelectionFormValues) => void },
    LaneSelectionSummaryValues
  >
> = ({ isSelected, actions, form, summary }) => {
  const { nextStage, previousStage } = actions
  const { values, onChange } = form
  return isSelected ? (
    <LaneSelectionForm nextStage={nextStage} previousStage={previousStage} data={values} onChange={onChange} />
  ) : (
    <LaneSelectionSummary {...summary} />
  )
}

export default LaneSelectionBlock
