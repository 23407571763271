import React, { type FC } from 'react'

import FormBlockWithActions from './FormBlockWithActions'

import type { StageActions, StageData } from './viewModel'

const VesselSelectionForm: FC<StageActions> = ({ nextStage, previousStage }) => {
  return (
    <FormBlockWithActions nextStage={nextStage} previousStage={previousStage}>
      Vessel selection form
    </FormBlockWithActions>
  )
}

const VesselSelectionSummary: FC<{}> = () => {
  return <div>Vessel selection summary</div>
}

const VesselSelectionBlock: FC<StageData<any, any>> = ({ isSelected, actions }) => {
  const { nextStage, previousStage } = actions
  return isSelected ? (
    <VesselSelectionForm nextStage={nextStage} previousStage={previousStage} />
  ) : (
    <VesselSelectionSummary />
  )
}

export default VesselSelectionBlock
