import { useState, useMemo } from 'react'

import { Combobox } from '@headlessui/react'
import { PlaceOutlined } from '@mui/icons-material'

import { LaneId } from '../../../generated/graphql'
import { useSettingsContext } from '../../../providers/SettingsProvider'
import {
  ComboboxWrapper,
  ComboboxButton,
  ComboboxOptions,
  ComboboxOption,
  ComboboxInput,
} from '../../../ui/Combobox/Combobox'

import styles from './LaneSelector.module.scss'

export function LaneSelectorCombobox({ lane, handleChange }: { lane?: string; handleChange: (item: string) => void }) {
  const [query, setQuery] = useState('')
  const { lanes } = useSettingsContext()
  const filteredLanes = useMemo(
    () =>
      query === ''
        ? Object.entries(lanes)
        : Object.entries(lanes).filter(([, val]) => val.toLowerCase().includes(query.toLowerCase())),
    [lanes, query]
  )

  return (
    <ComboboxWrapper className={styles.menu}>
      <Combobox value={lane ?? ''} onChange={handleChange}>
        <ComboboxInput className={styles.comboboxInput}>
          <label>
            <PlaceOutlined />
            <Combobox.Input
              placeholder="Select lane"
              displayValue={(item: LaneId | undefined) => (item ? lanes[item] : '')}
              onChange={({ target: { value } }) => setQuery(value)}
            />
          </label>
          <ComboboxButton />
        </ComboboxInput>
        <ComboboxOptions afterLeave={() => setQuery('')}>
          {filteredLanes.map(([key, val]) => (
            <ComboboxOption className={styles.menuItem} key={key} value={key}>
              {val}
            </ComboboxOption>
          ))}
        </ComboboxOptions>
      </Combobox>
    </ComboboxWrapper>
  )
}
